.music-timer {
    width: 100%;
    height: 4px;
    background-color: #141414;
    grid-row: 9/10;
}

.completed {
    height: 4px;
    width: 60%;
    background-color: yellow;  
}
