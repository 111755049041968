html {
    overflow-y: hidden;
    background-color: #141414;
}

body {
    margin: 0;
    font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
* {
    cursor: default;
}

*::selection {
    background-color: rgb(15, 23, 42);
    color: yellow;
}

#root {
    width: 100%;
    height: 100vh;
    background-color: rgb(15, 23, 42);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(8, 1fr);
}

@media (max-width: 720px) {
    .song-detail {
        grid-template-columns: 100%;
    }
}

#active {
    color: yellow;
}

#resolution-warning {
    font-weight: 400;
    font-size: 1.6rem;
    display: none;
}
