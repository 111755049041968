#player {
    grid-column: 1/13;
    grid-row: 8/9;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(20, 20, 20);
    flex-wrap: wrap;
}

i.fas,
svg {
    color: #94a3b8;
    font-size: 1.5rem;
    margin: 0px 2vw;
    transition: all 0.19s ease-in-out;
}
#active > svg {
    color: yellow;
}
i.fas.fa-play-circle,
i.fas.fa-pause-circle {
    font-size: 1.9rem;
}
.control,
.main-control > i {
    color: #e2e8f0;
}

.main-control > i:hover,
.control > svg:hover {
    color: #e2e8f0;
}

i:hover, svg:hover {
    transform: scale(1.3);
}
