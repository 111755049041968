.song-item.header {
    grid-row: 2/3;
    grid-column: 6/13;
    border-bottom: 1px solid #94a3b8;
    /* margin-left: 20px; */
}

.song-item.header > * {
    text-align: center;
}

@media (max-width: 760px) {
    .song-item.header {
        grid-column: 1/13;
    }
}
