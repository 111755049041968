.song-detail {
  grid-row: 2/8;
  grid-column: 1/6;
  color: white;
  background-color: #1e293b;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 30% 1fr 1fr 1fr;
  background-size: cover;
  background-color: "black";
  transition: all 0.1s ease-in-out;
}

.song-detail:hover {
  opacity: 1;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: scale(1.01);
}

@media (max-width: 720px) {
  .song-detail {
      display: none;
  }
}

.now-playing > .header {
  font-weight: 400;
  font-size: 1.5rem;
}

.now-playing {
  background-color: #000;
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  height: fit-content;
  opacity: 0.7;
}
.now-playing > * {
  margin: 12px;
}
@media (max-width: 760px) {
  .song-detail {
    display: none;
  }
}

.song-detail > div {
  width: 100%;
  height: 100%;
}

.album-img {
  grid-row: 2/6;
  grid-column: 1/3;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.7;
  display: flex;
  justify-content: center;
}

.artist-img {
  grid-row: 4/5;
  grid-column: 1/3;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.album-img > img {
  height: 100%;
}
.album-img {
  height: 100%;
  width: 100%;
  /* background-color: transparent; */
}
.artist-img > img {
  height: 64px;
  border-radius: 1000%;
}

.artist-img > .artist-name {
  color: white;
}

.artist-img {
  background-color: #000;
  opacity: 0.7;
}
